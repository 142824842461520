// Enable global box-sizing(border-box)
@include global-box-sizing(border-box)

body
  background: image-url('texture3.jpg')

html
  @media (min-width: 1600px)
    font-size: 20px
  @media (max-width: 700px)
    font-size: 15px

.container
  // We can pass a parameter to the grid-media-container() function to define
  // a larger max-width than the desktop breakpoint.
  // @see src/vendors/sass/stimul-breakpoint/_stimul-breakpoint.sass
  +grid-media-container()

// Clearfix
.clearfix
  +clearfix

.pie-clearfix
  +pie-clearfix

.relative
  position: relative
