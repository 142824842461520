.site-footer
  margin-top: rem(120px)
  padding: rem(48px) 0 rem(66px) 0
  &__title
    margin-bottom: rem(32px)

  a
    color: #fff

    &:hover,
    &:focus
      text-decoration: none
