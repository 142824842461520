// Margin
[data-margin~="top"]
  margin-top: $spacing

[data-margin~="top--l"]
  margin-top: $spacing--l

[data-margin~="top--xl"]
  margin-top: $spacing--xl

[data-margin~="top--xxl"]
  margin-top: $spacing--xxl

[data-margin~="bottom"]
  margin-bottom: $spacing

[data-margin~="bottom--l"]
  margin-bottom: $spacing--l

[data-margin~="bottom--xl"]
  margin-bottom: $spacing--xl

[data-margin~="bottom--xxl"]
  margin-bottom: $spacing--xxl
