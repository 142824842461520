// Generic columns.
[data-grid~="1/2"]
  +span(3)

  +desktop
    +span(6)

[data-grid~="1/3"]
  +span(2)

  +desktop
    +span(4)

[data-grid~="2/3"]
  +span(4)

  +desktop
    +span(8)

[data-grid~="1/4"]
  +desktop
    +span(3)

[data-grid~="3/4"]
  +desktop
    +span(9)

// Breakpoint specific columns.
[data-grid~="tablet--1/2"]
  +tablet-only
    +span(3)

[data-grid~="desktop--1/2"]
  +desktop
    +span(6)

[data-grid~="tablet--1/3"]
  +tablet-only
    +span(2)

[data-grid~="desktop--1/3"]
  +desktop
    +span(4)

// Last column class.
[data-grid~="last"]
  +last

[data-grid~="last--mobile"]
  +mobile-only
    +last

[data-grid~="last--tablet"]
  +tablet-only
    +last

[data-grid~="last--desktop"]
  +desktop
    +last
