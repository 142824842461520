// Font

$bodoni: 'Bodoni W01 LT', serif
$avenir: 'Avenir W01', sans-serif


$base-font-size: 17px
$base-line-height: 1.5
$em-base: $base-font-size // for bourbon px-to-em function.
$base-font-family: $avenir

// Disable bourbon prefixing, handled by autoprefixer.
$prefix-for-webkit: false
$prefix-for-mozilla: false
$prefix-for-microsoft: false
$prefix-for-opera: false
$prefix-for-spec: false
