html
  +font-smoothing
  font-family: $base-font-family
  font-size: 100% * ($base-font-size / 16px)
  line-height: $base-line-height
  letter-spacing: 1.11px
  text-shadow: -1px 1px 0px #FFFFFF
  text-align: center

// Set default heading color.
h1, h2, h3, h4, h5, h6
  color: $header-color

// Set default links color.
a
  +link-colors($link-color, $link-hover-color, $link-active-color, $link-visited-color, $link-focus-color)

// Remove default paragraph margin top.
p
  margin-top: 0

// utils
.hidden
  position: absolute !important
  clip: rect(1px 1px 1px 1px) /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px)
  padding: 0 !important
  border: 0 !important
  height: 1px !important
  width: 1px !important
  overflow: hidden

.font--l
  font-size: rem(20px)
  line-height: 1.3

.font--xl
  font-family: $bodoni
  font-size: rem(36px)
  letter-spacing: 2px

.font--medium
  font-size: rem(18px)
  letter-spacing: 1px
  font-weight: 500
  line-height: 1.6

.uppercase
  text-transform: uppercase

.no-shadow
  text-shadow: none
