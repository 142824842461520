// Spacings.
$spacing: 20px;
$spacing--s: $spacing / 2;
$spacing--xs: $spacing / 3;
$spacing--l: $spacing * 2;
$spacing--xl: $spacing * 3;
$spacing--xxl: $spacing * 4;


// Susy Configuration.
$susy: (
  columns: 2,
  gutters: (30/67),
  global-box-sizing: border-box
);

$desktop_layout: (
  columns: 12,
  gutters: (30/67)
);


// Breakpoints reference.
$breakpoint-iphone-portrait: 320px;
$breakpoint-iphone-landscape: 480px;
$breakpoint-ipad-portrait: 768px;
$breakpoint-ipad-landscape: 1024px;


// Define breakpoints.
// @see src/vendors/sass/stimul-breakpoint/_stimul-breakpoint.sass
$breakpoints: (
  "mobile": $breakpoint-iphone-portrait,
  "tablet": $breakpoint-ipad-portrait,
  "desktop": 960px
);

// We can have different margins by breakpoint.
$breakpoints_margin: (
  "mobile": 22px,
  //"tablet": 15px,
  //"desktop": 30px
);
