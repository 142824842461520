// Visibility
[data-visibility~="hidden"]
  display: none

[data-visibility~="mobile--hidden"]
  +mobile-only
    display: none

[data-visibility~="tablet--hidden"]
  +tablet-only
    display: none

[data-visibility~="desktop--hidden"]
  +desktop
    display: none
