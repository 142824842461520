.articles
  margin-top: rem(90px)

.article
  margin-bottom: rem(100px)

  &:last-child
    margin-bottom: 0

  &::after
    content: ''
    display: block
    margin: rem(48px) auto 0
    width: 329px
    height: 13px
    max-width: 100%

    background: image-url('hr.png') no-repeat
    background-size: 329px auto
    background-position: 50% 50%

  &__image
    padding: 2px
    background: #fff
    margin: 0 auto rem(45px) auto

  img
    display: block
    max-width: 100%
    width: auto !important
    height: auto !important

  p:last-child
    margin-bottom: 0

  &__title
    font-weight: 400
    &::before,
    &::after
      content: '-'
      display: inline-block

    &::before
      margin-right: 10px
    &::after
      margin-left: 10px
